<template>
  <div class="dir-box">
    <div class="block-chairman hidden-xs" v-if="typeof (info.chairman) !== 'undefined'">
      <transition name="fade">
        <photo name="chairman" :photo="chairmanPhoto ? chairmanPhoto:false"/>
      </transition>
      <div class="kris-dirtxt">
        <transition name="fade">
          <div class="kris-dirtxt-marg" v-if="info">
            <div v-if="sovet == 'Наблюдательный совет'" class="title">
              Председатель наблюдательного совета
            </div>
            <div v-else class="title">
              Председатель управляющего совета
            </div>
            <p>{{ info.chairman }}</p>
            <a class="link-more" v-if="sovet == 'Наблюдательный совет'" href="/sostav_soveta/">Подробнее о наблюдательном совете</a>
            <a class="link-more" v-else href="/sostav_soveta/">Подробнее об управляющем совете</a>
          </div>
        </transition>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Photo from './Photo'
import Loading from './Loading'
import { mapState } from 'vuex'

export default {
  name: 'Chairman',
  computed: {
    ...mapState({
      'chairmanPhoto': 'chairmanPhoto',
      'info': 'chairmanInfo',
      'sovet': 'sovet'
    })
  },
  components: {
    'photo': Photo,
    'loading': Loading
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
